import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import ScrollToTop from "./routes/helpers/ScrollToTop";
import { RatesWrapper } from "./context/rates";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer />
    <RatesWrapper>
      <HashRouter>
        <ScrollToTop />
        <App />
      </HashRouter>
    </RatesWrapper>
  </React.StrictMode>
);
