import React from "react";
import "./standalone-courses.css";
import { useRatesContext } from "../../context/rates";

const StandaloneCourses = () => {
  const { rate, symbol, currency, location } = useRatesContext();
  const PriceFormatter = new Intl.NumberFormat(`en-${location || "US"}`, {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const Format = (value) => {
    const a = Math.ceil(value / 10) * 10;
    return PriceFormatter.format(a);
  };

  return (
    <div className="custom-container">
      <h1>Standalone Courses</h1>
      <div className="standalone-contents mt-5">
        <div className="sa-card p-4">
          <div className="sa-card-title mb-2">
            <h4>Afrobeat Recording & Engineering</h4>
            {rate && symbol && <h3>{Format(700 * rate)}</h3>}
          </div>
          <p>
            Learn to record professional music from the comfort of your bedroom.
          </p>
          <a href="https://payments.krizbeatzmusicacademy.com/#/standalone-are">
            <button className="button sa-enrol-btn mt-4">Enrol</button>
          </a>
        </div>
        <div className="sa-card p-4">
          <div className="sa-card-title mb-2">
            <h4>Afrobeat Mixing & Mastering</h4>
            {rate && symbol && <h3>{Format(1000 * rate)}</h3>}
          </div>
          <p>
            Learn to record professional music from the comfort of your bedroom.
          </p>
          <a href="https://payments.krizbeatzmusicacademy.com/#/standalone-amm">
            <button className="button sa-enrol-btn mt-4">Enrol</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default StandaloneCourses;
