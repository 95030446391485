import { IoClose } from "react-icons/io5";
import { useRatesContext } from "../../context/rates";
import "./modal.css";
import ReactCountryFlag from "react-country-flag";

export default function Location_Notification_Modal() {
  const { location, currency, symbol, showNotif, setShowNotif } =
    useRatesContext();
  let regionNames = new Intl.DisplayNames(["en"], { type: "region" });

  if (!location || !currency || !symbol) {
    return;
  }

  return (
    <div className={`container ${showNotif === true ? "visible" : "hidden"} `}>
      <button onClick={() => setShowNotif(false)} className="close-button">
        <IoClose />
      </button>
      <div className="text-one">
        Your location has been set to{" "}
        <strong>{regionNames.of(location)}</strong>
      </div>
      <div className="flag-container">
        <ReactCountryFlag
          countryCode={location}
          svg
          style={{ width: "100%", height: "100%" }}
        />
      </div>
      <div className="text-two">
        Currency is set to
        <strong>
          {" "}
          {currency} ({symbol})
        </strong>
      </div>
    </div>
  );
}
