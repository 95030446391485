export const Country_Currency_Data = [
  {
    country: "NG",
    currency: "NGN",
    symbol: "₦",
  },
  {
    country: "US",
    currency: "USD",
    symbol: "$",
  },
  {
    country: "GB",
    currency: "GBP",
    symbol: "£",
  },
  {
    country: "EU",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "JP",
    currency: "JPY",
    symbol: "¥",
  },
  {
    country: "CA",
    currency: "CAD",
    symbol: "$",
  },
  {
    country: "AU",
    currency: "AUD",
    symbol: "$",
  },
  {
    country: "IN",
    currency: "INR",
    symbol: "₹",
  },
  {
    country: "CN",
    currency: "CNY",
    symbol: "¥",
  },
  {
    country: "CH",
    currency: "CHF",
    symbol: "Fr",
  },
  {
    country: "RU",
    currency: "RUB",
    symbol: "₽",
  },
  {
    country: "BR",
    currency: "BRL",
    symbol: "R$",
  },
  {
    country: "ZA",
    currency: "ZAR",
    symbol: "R",
  },
  {
    country: "KR",
    currency: "KRW",
    symbol: "₩",
  },
  {
    country: "MX",
    currency: "MXN",
    symbol: "$",
  },
  {
    country: "SE",
    currency: "SEK",
    symbol: "kr",
  },
  {
    country: "NO",
    currency: "NOK",
    symbol: "kr",
  },
  {
    country: "DK",
    currency: "DKK",
    symbol: "kr",
  },
  {
    country: "PL",
    currency: "PLN",
    symbol: "zł",
  },
  {
    country: "TH",
    currency: "THB",
    symbol: "฿",
  },
  {
    country: "NZ",
    currency: "NZD",
    symbol: "$",
  },
  {
    country: "SG",
    currency: "SGD",
    symbol: "$",
  },
  {
    country: "MY",
    currency: "MYR",
    symbol: "RM",
  },
  {
    country: "HK",
    currency: "HKD",
    symbol: "$",
  },
  {
    country: "ID",
    currency: "IDR",
    symbol: "Rp",
  },
  {
    country: "SA",
    currency: "SAR",
    symbol: "﷼",
  },
  {
    country: "AE",
    currency: "AED",
    symbol: "د.إ",
  },
  {
    country: "EG",
    currency: "EGP",
    symbol: "£",
  },
  {
    country: "PK",
    currency: "PKR",
    symbol: "₨",
  },
  {
    country: "BD",
    currency: "BDT",
    symbol: "৳",
  },
  {
    country: "AF",
    currency: "AFN",
    symbol: "؋",
  },
  {
    country: "AL",
    currency: "ALL",
    symbol: "L",
  },
  {
    country: "DZ",
    currency: "DZD",
    symbol: "د.ج",
  },
  {
    country: "AD",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "AO",
    currency: "AOA",
    symbol: "Kz",
  },
  {
    country: "AR",
    currency: "ARS",
    symbol: "$",
  },
  {
    country: "AM",
    currency: "AMD",
    symbol: "֏",
  },
  {
    country: "AT",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "AZ",
    currency: "AZN",
    symbol: "₼",
  },
  {
    country: "BS",
    currency: "BSD",
    symbol: "$",
  },
  {
    country: "BH",
    currency: "BHD",
    symbol: ".د.ب",
  },
  {
    country: "BB",
    currency: "BBD",
    symbol: "$",
  },
  {
    country: "BY",
    currency: "BYN",
    symbol: "Br",
  },
  {
    country: "BE",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "BZ",
    currency: "BZD",
    symbol: "$",
  },
  {
    country: "BJ",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    country: "BT",
    currency: "BTN",
    symbol: "Nu.",
  },
  {
    country: "BO",
    currency: "BOB",
    symbol: "Bs.",
  },
  {
    country: "BW",
    currency: "BWP",
    symbol: "P",
  },
  {
    country: "BN",
    currency: "BND",
    symbol: "$",
  },
  {
    country: "BG",
    currency: "BGN",
    symbol: "лв",
  },
  {
    country: "BF",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    country: "BI",
    currency: "BIF",
    symbol: "Fr",
  },
  {
    country: "KH",
    currency: "KHR",
    symbol: "៛",
  },
  {
    country: "CM",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    country: "CV",
    currency: "CVE",
    symbol: "$",
  },
  {
    country: "TD",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    country: "CL",
    currency: "CLP",
    symbol: "$",
  },
  {
    country: "CO",
    currency: "COP",
    symbol: "$",
  },
  {
    country: "KM",
    currency: "KMF",
    symbol: "Fr",
  },
  {
    country: "CG",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    country: "CR",
    currency: "CRC",
    symbol: "₡",
  },
  {
    country: "HR",
    currency: "HRK",
    symbol: "kn",
  },
  {
    country: "CU",
    currency: "CUP",
    symbol: "$",
  },
  {
    country: "CY",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "CZ",
    currency: "CZK",
    symbol: "Kč",
  },
  {
    country: "DJ",
    currency: "DJF",
    symbol: "Fr",
  },
  {
    country: "DM",
    currency: "XCD",
    symbol: "$",
  },
  {
    country: "DO",
    currency: "DOP",
    symbol: "$",
  },
  {
    country: "EC",
    currency: "USD",
    symbol: "$",
  },
  {
    country: "SV",
    currency: "USD",
    symbol: "$",
  },
  {
    country: "GQ",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    country: "ER",
    currency: "ERN",
    symbol: "Nfk",
  },
  {
    country: "EE",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "SZ",
    currency: "SZL",
    symbol: "E",
  },
  {
    country: "ET",
    currency: "ETB",
    symbol: "Br",
  },
  {
    country: "FJ",
    currency: "FJD",
    symbol: "$",
  },
  {
    country: "FI",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "FR",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "GA",
    currency: "XAF",
    symbol: "Fr",
  },
  {
    country: "GM",
    currency: "GMD",
    symbol: "D",
  },
  {
    country: "GE",
    currency: "GEL",
    symbol: "₾",
  },
  {
    country: "DE",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "GH",
    currency: "GHS",
    symbol: "₵",
  },
  {
    country: "GR",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "GD",
    currency: "XCD",
    symbol: "$",
  },
  {
    country: "GT",
    currency: "GTQ",
    symbol: "Q",
  },
  {
    country: "GN",
    currency: "GNF",
    symbol: "Fr",
  },
  {
    country: "GW",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    country: "GY",
    currency: "GYD",
    symbol: "$",
  },
  {
    country: "HT",
    currency: "HTG",
    symbol: "G",
  },
  {
    country: "HN",
    currency: "HNL",
    symbol: "L",
  },
  {
    country: "HU",
    currency: "HUF",
    symbol: "Ft",
  },
  {
    country: "IS",
    currency: "ISK",
    symbol: "kr",
  },
  {
    country: "IR",
    currency: "IRR",
    symbol: "﷼",
  },
  {
    country: "IQ",
    currency: "IQD",
    symbol: "ع.د",
  },
  {
    country: "IE",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "IL",
    currency: "ILS",
    symbol: "₪",
  },
  {
    country: "IT",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "CI",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    country: "JM",
    currency: "JMD",
    symbol: "$",
  },
  {
    country: "JO",
    currency: "JOD",
    symbol: "د.ا",
  },
  {
    country: "KZ",
    currency: "KZT",
    symbol: "₸",
  },
  {
    country: "KE",
    currency: "KES",
    symbol: "Sh",
  },
  {
    country: "KI",
    currency: "AUD",
    symbol: "$",
  },
  {
    country: "KW",
    currency: "KWD",
    symbol: "د.ك",
  },
  {
    country: "KG",
    currency: "KGS",
    symbol: "с",
  },
  {
    country: "LA",
    currency: "LAK",
    symbol: "₭",
  },
  {
    country: "LV",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "LB",
    currency: "LBP",
    symbol: "ل.ل",
  },
  {
    country: "LS",
    currency: "LSL",
    symbol: "L",
  },
  {
    country: "LR",
    currency: "LRD",
    symbol: "$",
  },
  {
    country: "LY",
    currency: "LYD",
    symbol: "ل.د",
  },
  {
    country: "LI",
    currency: "CHF",
    symbol: "Fr",
  },
  {
    country: "LT",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "LU",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "MG",
    currency: "MGA",
    symbol: "Ar",
  },
  {
    country: "MW",
    currency: "MWK",
    symbol: "MK",
  },
  {
    country: "MY",
    currency: "MYR",
    symbol: "RM",
  },
  {
    country: "MV",
    currency: "MVR",
    symbol: "Rf",
  },
  {
    country: "ML",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    country: "MT",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "MH",
    currency: "USD",
    symbol: "$",
  },
  {
    country: "MR",
    currency: "MRU",
    symbol: "UM",
  },
  {
    country: "MU",
    currency: "MUR",
    symbol: "₨",
  },
  {
    country: "YT",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "MX",
    currency: "MXN",
    symbol: "$",
  },
  {
    country: "FM",
    currency: "USD",
    symbol: "$",
  },
  {
    country: "MD",
    currency: "MDL",
    symbol: "Leu",
  },
  {
    country: "MC",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "MN",
    currency: "MNT",
    symbol: "₮",
  },
  {
    country: "ME",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "MA",
    currency: "MAD",
    symbol: "د.م.",
  },
  {
    country: "MZ",
    currency: "MZN",
    symbol: "MT",
  },
  {
    country: "MM",
    currency: "MMK",
    symbol: "K",
  },
  {
    country: "NA",
    currency: "NAD",
    symbol: "$",
  },
  {
    country: "NR",
    currency: "AUD",
    symbol: "$",
  },
  {
    country: "NP",
    currency: "NPR",
    symbol: "₨",
  },
  {
    country: "NL",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "NZ",
    currency: "NZD",
    symbol: "$",
  },
  {
    country: "OM",
    currency: "OMR",
    symbol: "﷼",
  },
  {
    country: "PW",
    currency: "USD",
    symbol: "$",
  },
  {
    country: "PG",
    currency: "PGK",
    symbol: "K",
  },
  {
    country: "PY",
    currency: "PYG",
    symbol: "₲",
  },
  {
    country: "PE",
    currency: "PEN",
    symbol: "S/",
  },
  {
    country: "PH",
    currency: "PHP",
    symbol: "₱",
  },
  {
    country: "PL",
    currency: "PLN",
    symbol: "zł",
  },
  {
    country: "PT",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "QA",
    currency: "QAR",
    symbol: "﷼",
  },
  {
    country: "RO",
    currency: "RON",
    symbol: "lei",
  },
  {
    country: "RS",
    currency: "RSD",
    symbol: "дин.",
  },
  {
    country: "RW",
    currency: "RWF",
    symbol: "Fr",
  },
  {
    country: "WS",
    currency: "WST",
    symbol: "T",
  },
  {
    country: "SM",
    currency: "EUR",
    symbol: "€",
  },
  {
    country: "ST",
    currency: "STN",
    symbol: "Db",
  },
  {
    country: "SA",
    currency: "SAR",
    symbol: "﷼",
  },
  {
    country: "SN",
    currency: "XOF",
    symbol: "Fr",
  },
  {
    country: "RS",
    currency: "RSD",
    symbol: "дин.",
  },
  {
    country: "SG",
    currency: "SGD",
    symbol: "$",
  },
];
