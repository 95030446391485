import React from "react";

const TermsOfUse = () => {
  return (
    <>
      <div className="custom-container">
        <p className="updated">Effective Date: March 1st, 2023 </p>
        <div className="policy-container">
          <ol>
            <li className="bold p-title">Introduction:</li>
            <p className="inner-lists">
              The Krizbeatz Music Academy (hereinafter referred to as "the
              Academy") operates a music education platform through its website,
              providing various music education services to students, including
              but not limited to, lessons, tutorials, and resources. The Academy
              is owned by World Wave Empire, with both entities being owned by
              Krizbeatz. These Terms of Service (hereinafter referred to as "the
              Terms") serve to govern the use of the Academy's Services by any
              student accessing or utilizing such Services (hereinafter referred
              to as "Student"). By accessing or using the Services offered by
              the Academy, the Student agrees to be bound by the Terms. If the
              Student does not agree to be bound by the Terms, the Student must
              not use the Services provided by the Academy.
            </p>
            <li className="bold p-title">Eligibility: </li>
            <p className="inner-lists">
              The Services are intended for individuals who are at least 18
              years old or the age of majority in the Student's jurisdiction,
              whichever is greater. If the Student is under 18 years of age, the
              Student must have a parent or legal guardian agree to the Terms on
              the Student's behalf. The Academy reserves the right to refuse the
              Services to anyone for any reason at any time.
            </p>
            <li className="bold p-title">
              Registration and Account Information:
            </li>
            <p className="inner-lists">
              The Student may be required to register and create an account in
              order to use the Services. The Student must provide accurate,
              current, and complete information during the registration process,
              and the Student must keep the information up-to-date. The Student
              is responsible for maintaining the confidentiality of the
              Student's account information and password, and the Student must
              not share the account information or password with any third
              party. The Student is solely responsible for any and all
              activities that occur under the Student's account.
            </p>
            <li className="bold p-title">Payment:</li>
            <p className="inner-lists">
              The Student must pay all fees associated with the Services,
              including but not limited to, tuition, materials, and other
              related fees. The Student must provide accurate and up-to-date
              payment information, and the Academy reserves the right to change
              the fees for the Services at any time. The Student is responsible
              for any fees or charges imposed by the Student's bank or payment
              provider. All our payment is processed through Flutterwave payment
              gateway, you can check Flutterwave website for their Terms of
              service
            </p>
            <li className="bold p-title">Use of Services:</li>
            <p className="inner-lists">
              The Services are provided for the Student's personal,
              non-commercial use. The Student may not use the Services for any
              illegal or unauthorized purpose. The Student may not reproduce,
              distribute, or otherwise use the Services in any manner that
              exceeds the scope of the license granted by the Academy. The
              Academy reserves the right to terminate the Student's access to
              the Services at any time for any reason, including but not limited
              to, violation of the Terms.
            </p>
            <li className="bold p-title">Intellectual Property:</li>
            <p className="inner-lists">
              The Services and all related content, including but not limited
              to, text, graphics, logos, music, and other materials, are the
              property of the Academy or its licensors, and are protected by
              copyright and other intellectual property laws. The Student may
              not use any of the Academy's content without express written
              permission from the Academy. The Academy may terminate the
              Student's access to the Services if the Student uses the Services
              or the Academy's content in a manner that infringes the Academy's
              intellectual property rights.
            </p>
            <li className="bold p-title">Disclaimer of Warranties:</li>
            <p className="inner-lists">
              The Academy provides the Services on an "as is" and "as available"
              basis, and the Academy makes no representations or warranties of
              any kind, express or implied, as to the operation of the Services
              or the content or materials included in the Services. The Academy
              does not warrant that the Services will be uninterrupted or
              error-free, and the Academy will not be liable for any
              interruptions or errors. The Student assumes all risks associated
              with the use of the Services.
            </p>
            <li className="bold p-title">Limitation of Liability: </li>
            <p className="inner-lists">
              The Academy will not be liable for any damages of any kind arising
              from the use of the Services, including but not limited to,
              direct, indirect, incidental, punitive, and consequential damages.
              The Academy will not be liable for any loss of profits, loss of
              data, or any other losses incurred by the Student. The Academy's
              liability to the Student is limited to the amount of fees paid by
              the Student for the Services. The Academy will not be liable for
              any damage to the Student's computer or mobile device, or for any
              other equipment or software, as a result of the Student's use of
              the Services.
            </p>
            <li className="bold p-title">Indemnification:</li>
            <p className="inner-lists">
              The Student agrees to indemnify and hold the Academy, its
              affiliates, and its licensors harmless from any and all claims,
              damages, and expenses arising from the Student's use of the
              Services, including but not limited to, any infringement of
              third-party intellectual property rights.
            </p>
            <li className="bold p-title">Modification of Terms:</li>
            <p className="inner-lists">
              The Academy may modify the Terms at any time, and the modified
              Terms will be posted on the Academy's website. The Student's
              continued use of the Services after any modifications to the Terms
              indicates the Student's acceptance of the modified Terms.
            </p>
            <li className="bold p-title">Governing Law:</li>
            <p className="inner-lists">
              The Terms will be governed and interpreted in accordance with the
              laws of the jurisdiction in which the Academy is located, without
              regard to its conflict of law provisions.
            </p>
            <li className="bold p-title">Dispute Resolution:</li>
            <p className="inner-lists">
              Any dispute arising from or related to the Terms will be resolved
              through binding arbitration in accordance with the commercial
              arbitration rules of the jurisdiction in which the Academy is
              located. The arbitration will be conducted by a single arbitrator,
              and the Student and the Academy will split the costs of the
              arbitration equally.
            </p>
            <li className="bold p-title">Entire Agreement:</li>
            <p className="inner-lists">
              The Terms constitute the entire agreement between the Student and
              the Academy, and supersedes all prior agreements and
              understandings between the Student and the Academy regarding the
              Services.
            </p>
            <li className="bold p-title">Assignment:</li>
            <p className="inner-lists">
              The Academy may assign the Terms, in whole or in part, at any time
              without notice to the Student. The Student may not assign the
              Terms or transfer any rights under the Terms without the prior
              written consent of the Academy.
            </p>
            <li className="bold p-title">Severability:</li>
            <p className="inner-lists">
              If any provision of the Terms is deemed invalid or unenforceable,
              the invalid or unenforceable provision will be severable from the
              Terms, and the remaining provisions will remain in full force and
              effect.
            </p>
            <li className="bold p-title">Waiver:</li>
            <p className="inner-lists">
              The failure of the Academy to enforce any right or provision in
              the Terms will not constitute a waiver of such right or provision
              unless acknowledged and agreed to by the Academy in writing.
            </p>
            <li className="bold p-title">Contact Information:</li>
            <p className="inner-lists">
              The Student may contact the Academy with any questions or concerns
              regarding the Terms or the Services at{" "}
              <a href="mailto:contact@krizbeatzmusicacademy.com">
                contact@krizbeatzmusicacademy.com
              </a>
            </p>
          </ol>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
