// import React, { useState, useEffect } from 'react'
import Assurance from "../../components/assurance/Assurance";
import Main from "../../components/main/Main";
import Nav from "../../components/navigation/Nav";
import SubMainVid from "../../components/sub_main/SubMainVid";
import AboutLanding from "../../components/about_landing/AboutLanding";
import HowToEnrol from "../../components/how_to_enrol/HowToEnrol";
import PricingSection from "../../components/pricing_page/PricingSection";
import OurClient from "../../components/our_client/OurClient";
import GetStarted from "../../components/get_started/GetStarted";
import CourseOutline from "../../components/course_outline/CourseOutline";
import Faqs from "../../components/faq/Faq";
import Footer from "../../components/footer/Footer";
import Modal from "../../components/modal/Modal";
import StandaloneCourses from "../../components/standalone_courses/standalone-courses";

const Landing = () => {

  return (
    <>
      <div>
        <Nav />
        <Main />
        <SubMainVid />
        <PricingSection />
        <StandaloneCourses />
        <Assurance />
        <AboutLanding />
        <HowToEnrol />
        <OurClient />
        <GetStarted />
        <CourseOutline />
        <Faqs />
        <Footer />
        <Modal />
      </div>
    </>
  );
};

export default Landing;
