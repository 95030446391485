import { createContext, useContext, useEffect, useState } from "react";
import { Country_Currency_Data } from "./country_currency_data";
import { toast } from "react-toastify";

const RatesContext = createContext(undefined);

export function RatesWrapper({ children }) {
  const [location, setLocation] = useState();
  const [currency, setCurrency] = useState();
  const [symbol, setSymbol] = useState();
  const [rate, setRate] = useState();
  const [showNotif, setShowNotif] = useState(false);

  const GetLocation = async () => {
    await fetch("https://api.country.is")
      .then((response) => {
        if (response.ok == true) {
          return response.json();
        } else {
          SetDefault();
        }
      })
      .then((data) => {
        if (data.country) {
          const info = Country_Currency_Data.filter(
            (obj) => obj.country == data.country
          );
          if (info.length > 0) {
            setLocation(info[0].country);
            setCurrency(info[0].currency);
            setSymbol(info[0].symbol);
          } else {
            setLocation(data.country);
            setCurrency("USD");
            setSymbol("$");
          }
        }
      })
      .catch(() => {
        SetDefault();
      });
  };

  const SetDefault = () => {
    toast.info(
      "We couldn't detect your location and currency details, default settings have been applied"
    );
    setLocation("US");
    setCurrency("USD");
    setSymbol("$");
    setRate(1);
  };

  const GetRate = async () => {
    await fetch("https://api.currencybeacon.com/v1/latest", {
      headers: { Authorization: `Bearer ncbnKXeXywNJfeI1r1IqjftxE0udlxEi` },
    })
      .then((response) => {
        if (response.ok == true) {
          return response.json();
        } else {
          SetDefault();
        }
      })
      .then((data) => {
        if (currency) {
          const value = data.rates[currency];
          if (value) {
            setRate(data.rates[currency]);
            setShowNotif(true);
          } else {
            SetDefault();
          }
        } else {
          SetDefault();
        }
      })
      .catch(() => {
        SetDefault();
      });
  };

  useEffect(() => {
    GetLocation();
  }, []);

  useEffect(() => {
    if (currency) {
      GetRate();
    }
  }, [currency]);

  return (
    <RatesContext.Provider
      value={{ location, currency, symbol, showNotif, setShowNotif, rate }}
    >
      {children}
    </RatesContext.Provider>
  );
}

export function useRatesContext() {
  return useContext(RatesContext);
}
